<template>
    <div class="card card-secondary">
        <div class="card-header">{{ formTitle }}</div>
        <ValidationObserver v-slot="{ invalid }">
            <form @submit="saveForm">
                <div class="card-body">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <label for="name">Name *</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter the name of the user" v-model="formData.name" required>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-6">
                            <ValidationProvider v-slot="{ errors }" rules="required|email">
                                <label for="email">Email *</label>
                                <input type="email" class="form-control" id="email" placeholder="Enter the email of the user" v-model="formData.email" required>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-6">
                            <ValidationProvider v-slot="{ errors }" :rules="(id === 0? 'required|': '')+'min:6'">
                                <label for="password">Password</label>
                                <input type="password" class="form-control" id="password" :placeholder="passwordPlaceHolder" v-model="formData.password" autocomplete="false">
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-6">
                            <div class="row">
                                <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                    <label for="upload_image">Image</label>
                                    <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                </div>
                                <div class="col-sm-6" v-if="formData.image">
                                    <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                    <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-12" v-if="!myAccount">
                            <label>Store Access</label>
                            <div class="form-check" v-for="store in stores" :key="store.id">
                                <input class="form-check-input" type="checkbox" :value="store.id" :id="'store_' + store.id" v-model="formData.stores">
                                <label class="form-check-label" :for="'store_' + store.id">
                                    {{ store.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import Vue from 'vue'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, min, email } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'This field is required'
});
extend('min', {
    ...min,
    message: 'This field must be {length} characters'
});
extend('email', {
    ...email,
    message: 'This field must be an email'
});
import {StoreService} from "@/services/store-service";
import {UserService} from "@/services/user-service";

let storeService = new StoreService();
let userService = new UserService();

export default {
    name: "User",
    data: function(){
        return {
            stores: [],
            upload_image: null,
            formData: {
                id : 0,
                role_id : 2,
                company_id: null,
                name: null,
                email: null,
                email_verified_at: null,
                password: null,
                permissions: [],
                stores: [],
                image: null,
                editable: null,
                status: null,
            },
            formTitle: '',
            passwordPlaceHolder: 'Enter password if you want to change, else leave it empty'
        }
    },
    props: {
        id:{
            type: Number,
            default: () => 0
        },
        myAccount: {
            type: Boolean,
            default: () =>  false
        },
        saveHandler: {
            type: Function,
            default: function () {}
        },
    },
    components: {
        ValidationObserver, ValidationProvider
    },
    methods:{
        loadStores: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await storeService.getStores(false);
            if (response.isSuccess) {
                this.stores = response.stores;
                if (this.id === 0 && this.stores.length > 0) {
                    this.formData.stores.push(this.stores[0].id);
                }
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
                //await this.$router.push({name: 'App.Users'});
            }
            loader.hide();
        },
        async loadSavedData() {
            console.log('user load saved data: ',this.id);
            if (this.id !== 0) {
                this.formData.id = this.id;
                let loader = this.$loading.show();
                let response = await userService.getUser(this.formData.id, 'edit');
                if (response.isSuccess) {
                    this.formData = response.user;
                    this.formData.stores = JSON.parse(response.user.stores);
                    this.formTitle = this.myAccount? 'Update Account': 'Update User: ' + this.formData.name;
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    //await this.$router.push({name: 'App.Users'});
                    await this.saveHandler();
                }
                loader.hide();
            }
        },
        setUploadFile(){
            this.upload_image = this.$refs.upload_image.files[0];
        },
        async saveForm(e) {
            e.preventDefault();

            let save = false;
            if(this.myAccount){
                save = true;
            }else{
                if(this.formData.stores.length > 0){
                    save = true;
                }else{
                    save = false;
                    Vue.$toast.open({message: 'Please select at least one Store for the user!', type: 'error'});
                }
            }
            if(save) {
                let loader = this.$loading.show();
                userService.formDataUser = this.formData;
                let response = await userService.saveUser(this.upload_image);
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.loadSavedData();
                    await this.saveHandler();
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            }
        },
        unsetUploadFile(){
            if(confirm("Are you sure?")) {
                this.formData.image = null;
            }
        }
    },
    async mounted() {
        console.log('user mounted: ',this.id);
        if (this.id === 0) {
            this.formTitle = 'Add User';
            this.passwordPlaceHolder = 'Enter a password ';
        } else {
            await this.loadSavedData();
        }
        await this.loadStores();
    }
}
</script>

<style scoped>

</style>